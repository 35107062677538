export const useUseCases = async () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName, goto, getUrlFromLink } = useUtils();
  const config = await useConfig();

  const { data } = await storyblokApi.get("cdn/stories", {
    ...storyblokApiOptions,
    starts_with: "challenges/",
    is_startpage: 0,
  });
  const useCases = computed(() =>
    data.stories.map((story) => localeStoryName(story)),
  );

  function show(useCase: Object) {
    goto(
      getUrlFromLink({
        url: useCase.full_slug,
        linktype: "story",
      }),
    );
  }

  const linkText = (useCase) => {
    return config.more_about_link_text.replace("{label}", useCase.name);
  };

  const link = (useCase) => {
    return {
      link: {
        url: useCase.full_slug,
        linktype: "story",
      },
      linkText: linkText(useCase),
      button: true,
    };
  };

  return {
    useCases,
    show,
    link,
  };
};
